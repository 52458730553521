html {
  height: 100%;
  -webkit-overflow-scrolling: touch !important;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  /* font-size: 16px; */
  @media (max-width: 1000px) {
    font-size: calc(12px + 0.4vw);
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  height: 100%;
}

#root {
  width: 100%;
  height: 100%;
  font-family: 'Roboto';
}
